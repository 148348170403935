import { Flex, Button, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Layout } from '@common'
import { useRouter } from 'next/router'
import { IGlobal } from '@interfaces'

const NotFoundScreen = ({ global: { navigation } }: IGlobal) => {
  const { t } = useTranslation()
  const { route, push } = useRouter()

  return (
    <Layout
      title={t('common:notFoundLabel')}
      navigation={navigation}
      path={route}
    >
      <Flex
        height="calc(100vh - 40px)"
        justifyContent="center"
        backgroundImage="url('/img/not-found/not-found-bg.jpg')"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        position="relative"
        _before={{
          content: `""`,
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          background: 'rgba(13, 16, 29, 0.7)',
        }}
        sx={{
          '& > div': {
            zIndex: 1,
          },
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={{ base: 'padding.primary', lg: '0' }}
        >
          <Text
            as="h2"
            color="red.primary"
            fontSize={{ base: '2xl', lg: '3xl' }}
            textAlign="center"
          >
            {t('common:notFoundLabel')}
          </Text>
          <Text
            as="h1"
            fontWeight="bold"
            fontSize={{ base: '4-5xl', lg: '5xl' }}
            color="whiteAlpha.primary3"
            textAlign="center"
            lineHeight="none"
          >
            404
          </Text>
        </Flex>
        <Button
          position="absolute"
          right="0"
          bottom="0"
          height="100px"
          width="240px"
          fontWeight="medium"
          fontSize="sm"
          onClick={() => push('/')}
        >
          {t('common:backToHomepage')}
        </Button>
      </Flex>
    </Layout>
  )
}

export default NotFoundScreen
